@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

body {
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0;
    // font-family: "Montserrat", sans-serif;
}

// Flex Start
// =============================
.d-flex {
    display: flex !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

// Flex End
// =============================

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.vh-100 {
    height: 100vh;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mr-10 {
    margin-right: 10px;
}

.mt-0 {
    margin-top: 0;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.p-0 {
    padding: 0;
}

.p-40 {
    padding: 40px;
}

.d-block {
    display: block;
}

.d-none {
    display: none;
}

.inline-block {
    display: inline-block;
}

.text-center {
    text-align: center;
}

.float-right {
    float: right;
}