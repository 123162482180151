#private-layout-container {
    height: 100vh;

    .koza-layout-header {
        padding: 0 24px 0 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
        z-index: 1;
        display: flex;
        justify-content: space-between;

        .user-header-menu-container {
            cursor: pointer;
        }

        #lang-container {
            background-color: transparent;
            margin-right: 10px;
        }
    }

    .koza-layout-footer {
        text-align: center;
        padding: 15px 50px;
    }

    #logo {
        width: 100%;
        height: 100px;
        margin: 20px 0;
        cursor: pointer;
    }

    #logo-small {
        width: 100%;
        height: 60px;
        margin: 15px 0;
        cursor: pointer;
    }

    #site-layout {
        .trigger {
            padding: 0 24px;
            font-size: 18px;
            line-height: 64px;
            cursor: pointer;
            transition: color 0.3s;

            &:hover {
                color: #1890ff;
            }
        }
    }

    #private-layout-content {
        overflow: auto;

        #private-layout-inner-content {
            padding: 24px;
        }
    }
}