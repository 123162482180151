.koza-form {
    .koza-form-item-required {
        &::before {
            display: none !important;
        }
    }

    .koza-switch-container {
        label {
            margin-top: 5px;
        }
    }
}