#profile-container {
    #header {

        #full-name {
            font-size: 18px;
            font-weight: 500;
            margin-right: 12px;
        }

        .item {
            margin-bottom: 5px;

            i {
                font-size: 18px;
                margin-right: 5px;
            }

            a {
                color: inherit;

                &:hover {
                    color: #1890ff;
                }
            }
        }
    }
}