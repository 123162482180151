#auth-container {
    display: flex;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background-color: #f8f8f8;

    #right {
        background-color: #fff;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }

    #left {
        @media only screen and (max-width: 768px) {
            display: none !important;
        }
    }

    #logo {
        width: 200px;
        position: absolute;
        top: 30px;
        left: 50px;
    }

    #logo2 {
        width: 170px;
        display: table-cell;
        margin: 0 auto 30px auto;
        display: none;

        @media only screen and (max-width: 768px) {
            display: block !important;
        }
    }

    #lang-container {
        text-align: right;
        padding: 10px 10px 5px 0;
    }

    #inner-container {
        height: calc(100% - 50px);
    }
}


// #auth-container {
//     background-color: #f8f8f8;
//     padding: 30px;

//     #auth-content {
//         min-height: calc(100vh - 112px);

//         #inner-container {
//             border: 1px solid #ccc;
//             border-radius: 7px;
//             padding: 25px;
//             width: 400px;

//             #title {
//                 text-align: center;
//                 display: block;
//                 margin-bottom: 30px;
//                 font-size: 20px;
//             }
//         }
//     }

//     #lang-container {
//         width: 100%;
//         text-align: right;
//         margin-bottom: 20px;
//     }
// }